import React from "react";

import {
  Box,
  Flex,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

import ben from "../assets/ben.jpg";
import ryan from "../assets/ryan.jpg";
import farm1 from "../assets/farm1.jpg";

const benBio = `
Tim is our freshwater sexpert.

He is a down to drink, boots in the mud rural profeshinal.

Pashinit about futureproofing New Zealand agriculture and the importance of telling the farmers they are currently on a farm. Fuck the environment. After graduating at Canterbury University, he spent time in Canterbury, learning of the vital role Canterbury played in Canterbury, he pursued further study in Canterbury before working as a Canterbury advisor for the Canterbury regional council.

Tim brings together his knowledge of christianity, and the bible, to break down science guff into practical workable prayers on farm. Specialising in church basement plans, child choir plans and molesting strategies.

Recently graduating from the Rhys Vickers Rural Strip Mining Programme he continues his mining journey as a strong minecraft player. When he is not in the office or out fapping you will find him in the office fapping. Heil Ohakune.
`;

const ryanBio = `
Ryan is our land use and soil specialist.

Growing up on the family farm in Whangaehu Ryan has a passion for farming and an an affinity for managing and protecting on farm resources.
Ryan has experience in soil identification and farm mapping.
His experience in prospecting for ore in Western Australia and farming experience post study has given him a wealth of knowledge he brings to the team.

Prior to joining Falcon Ryan was a land management advisor for Regional Council specialising in soil mapping, LUC, erosion control and GIS analysis.
Ryan has a keen focus on utilising new technologies in the farming sector to maximise production and efficiencies, while mitigating environmental losses and promoting sustainability. He has a unique crossover of skills, understanding farm systems on a practical level with the ability to implement technical solutions when needed.
`;

const benskills = [
  "Freshwater policy advice.",
  "Practical on farm mitigations strategies.",
  "Riparian, wetland design and management.",
  "Drone mapping and GIS analysis.",
  "Natural resources mapping.",
  "Water quality testing.",
  "Freshwater education and workshop facilitation.",
  "Freshwater farm planning.",
  "Farm environment planning.",
];

const benQualifications = [
  "Bachelor of Science; Majoring in Chemistry – University of Canterbury",
  "Postgraduate Diploma in Water Resource Management – Lincoln University",
  "Intermediate Sustainable Nutrient Management – Massey University",
  "Intermediate Farm Environment Planning – Massey University",
  "Postgraduate Certificate in Commerce – Lincoln University",
  "Postgraduate Certificate in Business Management - Massey University",
  "Kellogg Rural Leadership Programme Graduate",
  "Young Farmer of the Year - Regional Finalist ",
];

const ryanSkills = [
  "LUC mapping.",
  "Natural resources mapping.",
  "Soil identification and mapping.",
  "Erosion management.",
  "Drone mapping and GIS analysis.",
  "Farm systems planning.",
  "ETS advice.",
  "Freshwater farm planning.",
  "Farm environment planning.",
];

const ryanQualifications = [
  "Bachelor of AgriScience; Majoring in Soil Science - Massey University.",
  "Graduate Diploma of Plant Biotechnology - Otago University.",
  "Advanced Farm Environment Planning - Massey University",
  "Greenhouse Gas Emissions and Climate Change Management in Agriculture - Massey University.",
  "Remotely Piloted Aircraft Systems (RPAS) Part 101- Massey University, School of Aviation",
];

interface PersonProps {
  name: string;
  image: string;
  title: string;
  phone: string;
  email: string;
  bio: string;
  skills: string[];
  qualifications: string[];
}

function Person(props: PersonProps) {
  const { name, image, title, phone, email, bio, skills, qualifications } =
    props;
  return (
    <Flex flexDirection="column" gap="32px">
      <Flex gap="32px" flexDirection={{ base: "column", md: "row"}} alignItems="center">
        <Flex flexGrow="1" flexBasis="1">
          <Image
            src={image}
            alt="sfc"
            objectFit="cover"
            width="100%"
            height="100%"
            borderRadius="16px"
            boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
          />
        </Flex>
        <Flex flexDirection="column" flexGrow="1" flexBasis="1">
          <Box fontSize="40px" fontWeight="100">
            {name}
          </Box>
          <Box
            backgroundColor="black"
            height="0.5px"
            width="250px"
            marginBottom="8px"
            marginTop="8px"
          ></Box>
          <Box fontSize="25px" fontWeight="500">
            {title}
          </Box>
          <Box>
            {phone}
            <br />
            {email}
          </Box>
          <Box
            backgroundColor="black"
            height="0.5px"
            width="250px"
            marginBottom="8px"
            marginTop="8px"
          ></Box>
          <Box whiteSpace="pre-line" fontSize="16px" lineHeight="20px">
            {bio}
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection="column">
        <Accordion defaultIndex={[]} allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontSize="20px">
                  Skills
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box paddingLeft="30px">
                <ul>
                  {skills.map((skill) => (
                    <li>{skill}</li>
                  ))}
                </ul>
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" fontSize="20px">
                  Qualifications
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Box paddingLeft="30px">
                <ul>
                  {qualifications.map((val) => (
                    <li>{val}</li>
                  ))}
                </ul>
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </Flex>
  );
}

export function WhoWeArePage() {
  return (
    <Flex flexDirection="column" alignItems="center" gap="50px">
      <Flex flexDirection="column" maxWidth="1280px" padding="16px">
        <Flex flexDirection="row" justifyContent="center" paddingTop="32px">
          <Box
            fontSize="48px"
            fontFamily="raleway, sans-serif"
            fontWeight="100"
            fontStyle="normal"
          >
            Who We Are
          </Box>
        </Flex>
        <Flex flexDirection="column" gap="50px">
          <Person
            image={ben}
            bio={benBio}
            name="Tim Fraser"
            title="Receptionist"
            phone="+64 27 811 4660"
            email="tfraser@falkin.farm"
            skills={benskills}
            qualifications={benQualifications}
          />
          <Person
            image={ryan}
            bio={ryanBio}
            name="Ryan Connors"
            title="Director"
            phone="+64 27 284 4467"
            email="rjconnors@falcon.farm"
            skills={ryanSkills}
            qualifications={ryanQualifications}
          />
        </Flex>
      </Flex>
      <Flex
        flexDirection="row"
        justifyContent="center"
        backgroundColor="gray"
        width="100%"
        height="500px"
        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
      >
        <Image
          src={farm1}
          alt="farm1"
          width="auto"
          height="100%"
          borderRadius="2px"
        />
      </Flex>
      <Flex height="100px" maxWidth="1280px" flexDirection="column" justifyContent="flex-end" padding="8px">
        <Box>©2023 Falcon Farm Planning Limited</Box>
      </Flex>
    </Flex>
  );
}
