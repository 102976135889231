import React from "react";

import { Box, Image, Flex } from "@chakra-ui/react";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";

import logo from "./assets/logo.png";
import title from "./assets/title.png";
import { HomePage, ContactPage, ServicePage, WhoWeArePage } from "./pages";

interface TitleLinkProps {
  title: string;
  path: string;
  isActive: boolean;
}

function TitleBarLink(props: TitleLinkProps) {
  const { title, path, isActive } = props;
  const navigate = useNavigate();
  let colour = isActive ? "white" : "gray";
  return (
    <Box
      color={colour}
      cursor="pointer"
      onClick={() => navigate(path)}
      _hover={{ color: "white" }}
    >
      {title}
    </Box>
  );
}

export function App() {
  const location = useLocation();
  return (
    <Flex flexDirection="column" overflow="hidden" height="100vh">
      <Flex
        backgroundColor="#2f2e2e"
        flexDirection="row"
        justifyContent="center"
        padding="16px"
        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
      >
        <Flex flexDirection="column" flexGrow="1" maxWidth="1280px" gap="16px">
          <Flex justifyContent="flex-start" flexDirection="row">
            <Flex flexDirection="row" alignItems="flex-end" gap="16px">
              <Box>
                <Image src={logo} alt="logo" width="95px" />
              </Box>
              <Box backgroundColor="white" width="1px" height="80%"></Box>
              <Flex flexDirection="column" justifyContent="flex-end">
                <Image src={title} alt="title" width="195px" />
              </Flex>
            </Flex>
          </Flex>
          <Flex gap="32px">
            <TitleBarLink
              title="Home"
              path="/"
              isActive={location.pathname === "/"}
            />
            <TitleBarLink
              title="Who We Are"
              path="/about"
              isActive={location.pathname === "/about"}
            />
            <TitleBarLink
              title="Services"
              path="/services"
              isActive={location.pathname === "/services"}
            />
            <TitleBarLink
              title="Contact"
              path="/contact"
              isActive={location.pathname === "/contact"}
            />
          </Flex>
        </Flex>
      </Flex>
      <Box overflowY="auto">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/about" element={<WhoWeArePage />} />
        </Routes>
      </Box>
    </Flex>
  );
}
